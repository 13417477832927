import React  from "react";

const PaymentResults=()=>{

return(

    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div style={{ color: 'green' }}>
        <h1>Ödeme Başarılı</h1>
        <p>Ödemeniz başarıyla tamamlandı. Teşekkür ederiz</p>
        <a href="/">Ana Sayfaya Dön</a>
      </div>
      </div>
);

}
export default PaymentResults;