// src/context/ImageContext.js
import React, { createContext, useContext } from 'react';

const ImageContext = createContext();

export const useImage = () => {
  return useContext(ImageContext);
};

export const ImageProvider = ({ children }) => {
  const getImageUrl = (image) => {
    if (image.startsWith('https://')) {
      return image;
    }
    return `https://panel.bikuyum.com/upload/${image}`;
  };

  return (
    <ImageContext.Provider value={{ getImageUrl }}>
      {children}
    </ImageContext.Provider>
  );
};
