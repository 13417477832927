
import React, { createContext, useReducer, useCallback } from 'react';



const initialState = {
    products: [],
    loading: true,
    error: null,
    productDetails: null,
    filteredProducts: [],  
    selectedCategory: null,
    store:null,
    currentPage: 1,
    loadingProduct: false, 
    maxPrice:"",
    minPrice:"",
    campaignProduct:[],
    sort: '',
    searchs:[],
};


const productReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCTS_SUCCESS':
            
            const existingIds = new Set(state.products.map(p => p.id));
            const newProducts = action.payload.filter(p => !existingIds.has(p.id));
            return {
                ...state,
                products: [...state.products, ...newProducts],
                loading: false
            };
        case 'FETCH_PRODUCTS_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'SET_SELECTED_PRODUCT':
            return {...state, productDetails: action.payload, loading: false, loadingProduct: false};
        case 'FETCH_CATEGORY_PRODUCTS_SUCCESS':
            const existingIdss = new Set(state.filteredProducts.map(p => p.id));
            const newUniqueProducts = action.payload.filter(p => !existingIdss.has(p.id));
            
            return { 
                ...state, 
                filteredProducts: state.currentPage === 1 
                    ? action.payload 
                    : [...state.filteredProducts, ...newUniqueProducts],
                selectedCategory: action.kategori, 
                loading: false
            };
        case 'FETCH_STORE_SUCCCESS':
            return{...state, store:action.payload, loading:false};
       
        case 'INCREMENT_PAGE':
            return { ...state, currentPage: state.currentPage + 1 };
        case 'FILTER_BY_PRICE':
            const filteredByPrice = state.filteredProducts.length > 0 ? state.filteredProducts : state.products;
            const filtered = filteredByPrice.filter(product => {
                const price = parseFloat(product.fiyat);
                return (
                (state.minPrice === '' || price >= parseFloat(state.minPrice)) &&
                (state.maxPrice === '' || price <= parseFloat(state.maxPrice))
                );
            });
            return {
                ...state,
                filteredProducts: filtered,
            };
            case 'SEARCH_PRODUCTS_SUCCESS':
                return { ...state, searchs: action.payload, loading: false }; 
                case 'RESET_PRODUCTS':
            return { ...state, products: [], loading: true,  currentPage: 1  };   
        case 'SET_MIN_PRICE':
            return {
            ...state,
            minPrice: action.payload,
            };
        case 'SET_MAX_PRICE':
            return {
            ...state,
            maxPrice: action.payload,
            };
            case 'SET_SORT_OPTION':
                return { ...state, sort: action.payload };
        case 'SET_LOADING':
                return { ...state, loading: action.payload, error:null };
        case 'FETCH_CAMPAIGN_PRODUCT_SUCCESS':
            return{...state, campaignProduct:action.payload, loading:false}
            case 'SET_HAS_MORE':
                return {
                    ...state,
                    hasMore: action.payload
                };
        default:
            return state;
    }
};

export const ProductContext = createContext();

export const ProductProvider = ({ children}) => {
    const [state, dispatch] = useReducer(productReducer, initialState);
    var son=false;
    const handleMinPriceChange = (e) => {
    
        dispatch({ type: 'SET_MIN_PRICE', payload: e.target.value });
      };
    
      const handleMaxPriceChange = (e) => {
        dispatch({ type: 'SET_MAX_PRICE', payload: e.target.value });
      };
    
      const filterByPrice = () => {
        dispatch({ type: 'FILTER_BY_PRICE' });
      };
        const fetchProducts = async (page) => {
           
            try {
                if(son){
                    return null;
                  }
                const token = localStorage.getItem('token');
                const tkn= localStorage.getItem('tkn');
                const response = await fetch(`https://api.bikuyum.com/api/products?page=${page}`,{
                    method: 'GET',
                    headers: {
                      'Authorization': `${token}`,
                      'Tkn':`${tkn}`, 
                      'Content-Type': 'application/json',
                      
                    },
                   
                  });
                const data = await response.json();
                if(data.length==0){
                    son=true;
                   }
                const correctedData = data.map(products => {
                    if (typeof products.image === 'string') {
                        
                        try {
                            const imageUrls = JSON.parse(products.image.replace(/\\/g, ''));
                            return {
                                ...products,
                                image: imageUrls 
                            };
                        } catch (error) {
                            console.error('Image JSON parse edilemedi:', error);
                            return products; 
                        }
                    }
                    return products; 
                });
                dispatch({ type: 'FETCH_PRODUCTS_SUCCESS',  payload: correctedData });
                
            } catch (error) {
                dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
        };
       
        const searchProducts =  useCallback(async (query) => {
           
            try {
                const token = localStorage.getItem('token');
                const tkn= localStorage.getItem('tkn');
                const response = await fetch(`https://api.bikuyum.com/api/products?search=${query}&limit=200`,{
                method: 'GET',
                headers: {
                  'Authorization': `${token}`,
                  'Tkn':`${tkn}`, 
                  'Content-Type': 'application/json',
                  
                },
               
              });
                const data = await response.json();
                const correctedData = data.map(searchs => {
                    if (typeof searchs.image === 'string') {
                        try {
                            const imageUrls = JSON.parse(searchs.image.replace(/\\/g, ''));
                            return { ...searchs, image: imageUrls };
                        } catch (error) {
                            console.error('Image JSON parse edilemedi:', error);
                            return searchs;
                        }
                    }
                    return searchs;
                });
                dispatch({ type: 'SEARCH_PRODUCTS_SUCCESS', payload: correctedData });
            } catch (error) {
                dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
        }, [dispatch]);   
    
    const fetchProductById = async (sef) => {
        dispatch({ type: 'SET_LOADING_PRODUCT', payload: true });
        try {
            const token = localStorage.getItem('token');
            const tkn= localStorage.getItem('tkn');
            const response = await fetch(`https://api.bikuyum.com/api/product?sef=${sef}`,{
                method: 'GET',
                headers: {
                  'Authorization': `${token}`,
                  'Tkn':`${tkn}`, 
                  'Content-Type': 'application/json',
                  
                },
               
              });
            const productDetails = await response.json(); 
        if (typeof productDetails.image === 'string') {
            try {
                const imageUrls = JSON.parse(productDetails.image.replace(/\\/g, ''));
                return {...productDetails,
                    image :imageUrls };
            } catch (error) {
                console.error('Image JSON parse edilemedi:', error);
                return productDetails;
            }
            
        }
        
            dispatch({ type: 'SET_SELECTED_PRODUCT', payload: productDetails });
        } catch (error) {
            dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
        }
   
        const fetchCategoryProducts = useCallback (async (kategori, page, sort) => {
          
            try {
                if(son){
                    return null;
                  }
                const token = localStorage.getItem('token');
                const tkn= localStorage.getItem('tkn');
                
              const response = await fetch(`https://api.bikuyum.com/api/products?category=${kategori}&page=${page}&sort=${sort}`,{
                method: 'GET',
                headers: {
                  'Authorization': `${token}`,
                  'Tkn':`${tkn}`, 
                  'Content-Type': 'application/json',
                  
                },
               
              });
             
              const data = await response.json();
              if(data.length==0){
                son=true;
               }
              const correctedData = data.map(filteredProducts => {
                if (typeof filteredProducts.image === 'string') {
                  try {
                    const imageUrls = JSON.parse(filteredProducts.image.replace(/\\/g, ''));
                    return { ...filteredProducts, image: imageUrls };
                  } catch (error) {
                    console.error('Image JSON parse edilemedi:', error);
                    return filteredProducts;
                  }
                }
                return filteredProducts;
              });
              dispatch({ type: 'FETCH_CATEGORY_PRODUCTS_SUCCESS', payload:correctedData, kategori: kategori });
             
              dispatch({ type: 'INCREMENT_PAGE' }); 
            } catch (error) {
              dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
           },[]);
           
            const fetchStoreProduct = async () => {
                
                try {
                    if(son){
                        return null;
                      }
                    const token = localStorage.getItem('token');
                    const tkn= localStorage.getItem('tkn');
                    const response = await fetch('https://api.bikuyum.com/api/stores',{
                    method: 'GET',
                    headers: {
                      'Authorization': `${token}`,
                      'Tkn':`${tkn}`, 
                      'Content-Type': 'application/json',
                      
                    },
                   
                  });
                    const data = await response.json();
                    if(data.length==0){
                        son=true;
                       }
                    const correctedData = data.map(store => {
                        if (typeof store.image === 'string') {
                            
                            if (store.image.startsWith("http")) {
                                return { ...store, image: [store.image] }; 
                            }
                           
                            try {
                                const imageUrls = JSON.parse(store.image.replace(/\\/g, ''));
                                return { ...store, image: imageUrls };
                            } catch (error) {
                                console.error('Image JSON parse edilemedi:', error);
                                return store; 
                            }
                        }
                        return store;
                    });
                    dispatch({ type: 'FETCH_STORE_PRODUCT_SUCCESS', payload: correctedData });
                    
                } catch (error) {
                    dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
                }
            };
            const fetchCampaignProduct = async () => {
                try {
                    if(son){
                        return null;
                      }
                    const token = localStorage.getItem('token');
                    const tkn= localStorage.getItem('tkn');
                    const response = await fetch(`https://api.bikuyum.com/api/products?store=kampanyali-urunler&sort=random`,{
                    method: 'GET',
                    headers: {
                      'Authorization': `${token}`,
                      'Tkn':`${tkn}`, 
                      'Content-Type': 'application/json',
                      
                    },
                   
                  });
                    const data = await response.json();
                    if(data.length==0){
                        son=true;
                       }
                    const correctedData = data.map(campaignProduct => {
                        if (typeof campaignProduct.image === 'string' ) {
                            try {
                              const imageUrls = JSON.parse(campaignProduct.image.replace(/\\/g, ''));
                              return { ...campaignProduct, image: imageUrls };
                            } catch (error) {
                              console.error('Image JSON parse edilemedi:', error);
                              return campaignProduct;
                            }
                          }
                          return campaignProduct;
                        });
                    dispatch({ type: 'FETCH_CAMPAIGN_PRODUCT_SUCCESS', payload: correctedData });
                    
                } catch (error) {
                    dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
                }
            };
           
            const filterProductsByCategory = (categoryId, storeId) => {
                const filtered = state.products.filter(product => 
                    product.categoryId === categoryId && product.storeId === storeId
                );
                dispatch({ type: 'FILTER_PRODUCTS_BY_CATEGORY', payload: { filtered, categoryId, storeId } });
            };
          
    return (
        <ProductContext.Provider value={{ state,  dispatch, handleMaxPriceChange, fetchCampaignProduct, handleMinPriceChange, filterByPrice, searchProducts, filterProductsByCategory, fetchProductById, fetchProducts, fetchStoreProduct, fetchCategoryProducts }}>
            {children}
        </ProductContext.Provider>
    );
};