import React, { createContext, useReducer, useEffect, useCallback} from 'react';


const initialState={
    stores: [],
    selectedCategory:null,
    error:null,
    loading:true,
    storee:null,
    currentPage: 1,
} 


const storesReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_STORES_SUCCESS':
          const existingIds = new Set(state.stores.map(p => p.id));
          const newUniqueProducts = action.payload.filter(p => !existingIds.has(p.id));
      
          return {
              ...state,
              stores: state.currentPage === 1 
                  ? action.payload 
                  : [...state.stores, ...newUniqueProducts],
              selectedCategory: action.kategori, 
              loading: false,};
        case 'FETCH_PRODUCTS_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'FETCH_STORE_SUCCESS':
          return {...state, storee: action.payload, loading:false};
          case 'INCREMENT_PAGE':
            return { ...state, currentPage: state.currentPage + 1 };
         
        default:
            return state;
    }
};

export const StoreContext= createContext();
export const StoreProvider=({children})=> {
    const [state, dispatch]= useReducer(storesReducer, initialState);
    var son=false;
    
    
    const fetchStoreById = useCallback(async (kullanici_id, page, kategori) => {
        try {
          if(son){
            return null;
          }
          const token = localStorage.getItem('token');
          const tkn= localStorage.getItem('tkn');
            const response = await fetch(`https://api.bikuyum.com/api/products?store=${kullanici_id}&category=${kategori}&limit=16&page=${page}`,{
            method: 'GET',
            headers: {
              'Authorization': `${token}`,
              'Tkn':`${tkn}`, 
              'Content-Type': 'application/json',
              
            },
           
          });
            const data = await response.json();
           if(data.length==0){
            son=true;
           }
          const correctedData = data.map(stores => {
            if (typeof stores.image === 'string' ) {
              try {
                const imageUrls = JSON.parse(stores.image.replace(/\\/g, ''));
                return { ...stores, image: imageUrls };
              } catch (error) {
                console.error('Image JSON parse edilemedi:', error);
                return stores;
              }
            }
            return stores;
          });
    
          dispatch({ type: 'FETCH_STORES_SUCCESS', payload: correctedData });
          dispatch({ type: 'INCREMENT_PAGE' });
        } catch (error) {
          dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
        }
      }, []);


      

     
      const fetchStoreImage = async (kullanici_id) => {
        try {
            const response = await fetch(`https://api.bikuyum.com/api/store?sef=${kullanici_id}`);
            const storee = await response.json();
      
            dispatch({ type: 'FETCH_STORE_SUCCESS', payload:storee });
            
        } catch (error) {
            dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
        }
    };
    
    
     
    return (
        <StoreContext.Provider value={{ state, fetchStoreById, fetchStoreImage }}>
            {children}
        </StoreContext.Provider>
    );

}