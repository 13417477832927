import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';

const initialState = {
    formData: {
      ad: "",
      soyad: "",
      email: "",
      telefon: "",
      sifre: "",
      adres: "",
    },
      loading: true,
  };

  const userReducer = (state, action) => {
    switch (action.type) {
      case 'SET_LOADING':
        return { ...state, loading: action.payload };
      case 'SET_USER_DATA':
        return { 
          ...state, 
          formData: { 
            ad: action.payload.ad, 
            soyad: action.payload.soyad, 
            email: action.payload.email, 
            telefon: action.payload.telefon, 
            sifre: "", 
            adres: action.payload.adres 
          },
          loading: false 
        };
      case 'UPDATE_USER_DATA':
        return { ...state, formData: { ...state.formData, ...action.payload } };
      default:
        return state;
    }
  };



export const UserContext = createContext();

export const UserProvider = ({ children }) => {
 
    const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token'); 
      if (!token) {
        dispatch({ type: 'SET_LOADING', payload: false });
        return; 
      }
      try {
        const tkn= localStorage.getItem('tkn');
        const response = await fetch("https://api.bikuyum.com/api/user", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
            'Tkn':`${tkn}`, 
          },
        });
        const userData = await response.json(); 
        dispatch({ type: 'SET_USER_DATA', payload: userData });
      } catch (error) {
        console.error("Kullanıcı bilgileri yüklenemedi:", error);
      } 
    };

    fetchUserData();
  }, []);

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'UPDATE_USER_DATA', payload: { [name]: value } });
    
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
               const token = localStorage.getItem('token');
                const tkn= localStorage.getItem('tkn');
                const oldPassword = state.formData.sifre;
      await axios.put("https://api.bikuyum.com/api/user", state.formData,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Tkn':`${tkn}`,

      },
    });
      alert("Bilgiler başarıyla güncellendi.");
      if (state.formData.sifre && state.formData.sifre.trim() !== '') {
        logout(); 
      }
    } catch (error) {
      console.error("Bilgiler güncellenemedi:", error);
      alert("Bilgiler güncellenemedi.");
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tkn');
  
    window.location.href = "/login"; 
  };
  return (
    <UserContext.Provider value={{  formData: state.formData, handleInputChange, handleSubmit }}>
      {children}
    </UserContext.Provider>
  );
};