import React, { useEffect, useState, useContext } from 'react';
import { OrderContext } from '../../context/OrderContext.js';
import "./OrderDetails.css";
import {useImage} from "../../context/ImageContext.js";


const OrderDetails = () => {
  const { getImageUrl } = useImage();
    const { orders, loading, error, fetchOrders, getOrderStatusMessage } = useContext(OrderContext);

    useEffect(() => {
      fetchOrders(); 
    }, []); 
 


    if (loading) {
      return <div>Yükleniyor...</div>;
    }
  
    if (error) {
      return <div>Hata: {error}</div>;
    }

return (
  
    <div className='order-container' >
    {orders && orders.length < 0 ?  (
      
      <p>Henüz siparişiniz yok.</p>
    ) : (
      <div className='order-card'>
     {orders.map((order) => (
      
        <div key={order.id} className='order-item'>
          <div className='order-info'>
          <p><strong>Sipariş Tarihi:</strong> {new Date(order.siparis_tarihi).toDateString()}</p>
          <p><strong>Adres:</strong> {order.adres}</p>
          <p><strong>Toplam Tutar:</strong> {parseFloat(order.toplam_tutar).toFixed(2)} TL</p>
          <h4 className={`order-status ${order.products.length > 0 && order.products[0].durum === 0 ? 'pending' : 'success'}`}>
                                    {order.products.length > 0 ? getOrderStatusMessage(order.products[0].durum) : 'Durum yok'}
                                </h4>
        </div>
          <div className='order-body'>
          {orders.products && orders.products.length > 0 ? (
            orders.products.map((product, index) => (
              <div key={index} className='product-itemm'>
                <img src={getImageUrl(product.resim.i1)}  alt={product.baslik}/> 
                <p>{product.baslik}</p>
                <p>Adet: {product.quantity || 1}</p>
                <p>{parseFloat(product.fiyat).toFixed(2)} TL</p>
              </div>
            ))
          ) : (
            <p>Ürün bilgisi bulunamadı.</p>
          )}
          </div>
        </div>
      ))}</div>
    )}
  </div>
  );
};

export default OrderDetails;